import React, { useState } from 'react';
import styled from 'styled-components';
import { media, Row, Col as StyledCol } from 'styled-bootstrap-grid';

import { ListUserOrderData } from '../../../../types/order';
import CommonAccordion from '../../../common/accordion';
import { toMoment } from '../../../../utils';
import ProductImageSummaryView from './product-image-summary-view';
import { PriceLabel } from '../../../common';
import DownOrderInvoiceBtn from './download-order-invoice-btn';
import SvgChevron from '../../../../static/images/dashboard-order-chevron.svg';
import OrderBody from './order-body';
import { rem, lh } from '../../../../styles/utils';
import { OrderStatusBadge } from '../../common';

const Container = styled.div`
  font-weight: 400;
  font-family: var(--font-body);
`;

const Header = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;
  color: var(--cornflower-blue);
  padding: 0 20px;
`;

const Body = styled.div``;

const ProductImageView = styled(ProductImageSummaryView)`
  display: flex;
  align-items: center;

  [class^='product-image-summary-view__TruncateLabel'] {
    margin-left: 10px;
  }
`;

const Price = styled(PriceLabel)`
  margin: 0;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const RowContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 10px 20px;
`;

const Col = styled(StyledCol)`
  display: flex;
  align-items: center;
`;

const ChevronIcon = styled.div<{ isActive: boolean }>`
  margin-left: auto;
  transition: transform 0.3s ease-out;
  transform: ${({ isActive }) =>
    isActive ? 'rotate(180deg)' : 'rotate(0deg)'};

  ${media.lg`
    display: none;
  `}

  ${media.xl`
    display: block;
  `}
`;

const DownloadButton = styled(DownOrderInvoiceBtn)``;

const Accordion = styled(CommonAccordion)`
  .accordion-header.active ${RowContainer} {
    border-radius: 10px 10px 0 0;
  }
`;

const OrderDataTitle = styled.h4`
  font-family: var(--font-header);
  color: var(--font-primary-color);
  font-size: ${rem(24)};
  line-height: ${lh(21)};
  margin-bottom: 20px;
  margin-top: 0;
`;

const OrderDataContainer = styled.div`
  background-color: white;
  border-radius: 0 0 10px 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 30px 40px;
`;

const OrderData = styled(OrderBody)``;

type Props = {
  className?: string;
  orders: ListUserOrderData[];
};

const OrderAccordion: React.FC<Props> = ({ className, orders }) => {
  const [activeIndexes, setActiveIndexes] = useState<number[]>([]);

  const handleChange = (activeIndex: number | number[]) => {
    if (Array.isArray(activeIndex)) {
      setActiveIndexes([...activeIndex]);
    } else {
      setActiveIndexes([activeIndex]);
    }
  };

  return (
    <Container className={className}>
      <Header>
        <Row>
          <Col col={2}>Transaction date</Col>
          <Col col={2}>Order Number</Col>
          <Col col={3} />
          <Col col={2}>Status</Col>
          <Col col={2}>Order Amount</Col>
          <Col col={1}>Actions</Col>
        </Row>
      </Header>
      <Body>
        <Accordion
          onChange={handleChange}
          allowMultiple
          data={orders.map((order, index) => {
            const { refNum, orderedOn, products, totalPrice, currency } = order;

            return {
              title: (
                <RowContainer>
                  <Row>
                    <Col col={2}>
                      {toMoment(orderedOn).format('DD MMMM YYYY')}
                    </Col>
                    <Col col={2}>{refNum}</Col>
                    <Col col={3}>
                      <ProductImageView products={products} />
                    </Col>
                    <Col col={2}>
                      <OrderStatusBadge order={order} />
                    </Col>
                    <Col col={2}>
                      <Price
                        price={{
                          amount: totalPrice || 0,
                          currency,
                        }}
                      />
                    </Col>
                    <Col col={1}>
                      <ActionContainer>
                        <DownloadButton hideLabel order={order} />
                        <ChevronIcon isActive={activeIndexes.includes(index)}>
                          <SvgChevron />
                        </ChevronIcon>
                      </ActionContainer>
                    </Col>
                  </Row>
                </RowContainer>
              ),
              body: (
                <OrderDataContainer>
                  <OrderDataTitle>Order Details</OrderDataTitle>
                  <OrderData order={order} />
                </OrderDataContainer>
              ),
            };
          })}
        />
      </Body>
    </Container>
  );
};

export default OrderAccordion;
