import { useState, useEffect } from 'react';

import { Breakpoints } from '../styles/enums';

type Props = {
  xs: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
};

export default function useResponsiveCenterSlide(props: Props): number {
  const { xs, sm = xs, md = sm, lg = md, xl = lg } = props;
  const [percent, setPercent] = useState(xs);

  useEffect(() => {
    const updateCenterSlidePercentage = () => {
      if (document.documentElement.clientWidth >= Breakpoints.XL) {
        setPercent(xl);
      } else if (document.documentElement.clientWidth >= Breakpoints.LG) {
        setPercent(lg);
      } else if (document.documentElement.clientWidth >= Breakpoints.MD) {
        setPercent(md);
      } else if (document.documentElement.clientWidth >= Breakpoints.SM) {
        setPercent(sm);
      } else {
        setPercent(xs);
      }
    };

    window.addEventListener('resize', updateCenterSlidePercentage);
    updateCenterSlidePercentage();

    return () =>
      window.removeEventListener('resize', updateCenterSlidePercentage);
  }, []);

  return percent;
}
