import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { rem, lh } from '../../../../styles/utils';
import {
  ListUserOrderData,
  ListUserOrderProductItem,
} from '../../../../types/order';
import { HelpTooltip, Spinner } from '../../../common';
import {
  OrderCartSummary,
  ProductOrderItemInfo,
  ProductOrderItemPrice,
} from '../../../order';
import ProductWarrantyStatus from './product-warranty-status';
import { getAmounts } from '../../../../hooks/cart-context/cart-context-utils';
import { listUserProducts } from '../../../../utils/api';
import { useAuthState } from '../../../../hooks/auth-context';
import { UserProduct } from '../../../../types/product';

const Container = styled.div`
  font-family: var(--font-body);
  font-weight: 400;
  color: var(--font-primary-color);
`;

const AddressWrapper = styled.div`
  margin-bottom: 10px;

  ${media.lg`
    display: flex;
  `}
`;

const AddressContainer = styled.div`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;

    ${media.lg`
      margin-left: 50px;
    `}
  }
`;

const AddressEyebrow = styled.label`
  color: var(--cornflower-blue);
`;

const AddressInfo = styled.p`
  margin: 0;
  font-size: ${rem(14)};
  line-height: ${lh(16.8, 14)};
`;

const Tooltip = styled(HelpTooltip)`
  margin-left: 5px;
  opacity: 0.5;

  svg {
    width: 16px;
    height: 16px;
    fill: var(--cornflower-blue);
  }
`;

const TableHeaderLabelWithTooltip = styled.div`
  display: flex;
  align-items: center;
`;

const ProductTableContainer = styled.div`
  width: calc(100% + 15px);
  margin-left: -15px;
  overflow-x: scroll;
  padding-bottom: 10px;
  position: relative;
`;

const ProductTable = styled.table`
  width: 100%;

  thead {
    th {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: var(--cornflower-blue);
      font-size: ${rem(13)};
      line-height: ${lh(14.3, 13)};
      font-weight: 400;
      text-align: left;

      &:first-child {
        padding-left: 15px;
      }

      &:nth-child(4) {
        ${media.lg`
          text-align: center;
        `}
      }

      &:last-child {
        ${media.lg`
          text-align: right;
        `}
      }
    }
  }

  tbody {
    td {
      padding: 5px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: var(--sirocco);
      font-size: ${rem(14)};
      line-height: ${lh(15.4, 14)};

      &:first-child {
        padding-left: 15px;
      }

      &:nth-child(2) {
        min-width: 180px;
      }

      &:nth-child(3) {
        min-width: 200px;
      }

      &:nth-child(4) {
        min-width: 100px;
      }

      &:nth-child(5) {
        min-width: 80px;

        ${media.lg`
          text-align: center;
        `}
      }

      &:last-child {
        ${media.lg`
          text-align: right;
        `}
      }
    }
  }

  tfoot {
    display: none;

    ${media.lg`
      display: revert;
    `}
  }
`;

const Image = styled.img`
  width: 50px;
`;

const ProductInfo = styled(ProductOrderItemInfo)`
  strong {
    font-size: ${rem(16)};
    line-height: ${lh(17.6, 16)};
    margin-bottom: 4px;
    display: block;
  }

  span {
    font-size: ${rem(14)};
    line-height: ${lh(15.4, 14)};
  }
`;

const WarrantyStatus = styled(ProductWarrantyStatus)`
  margin: 15px 0;
`;

const ProductPrice = styled(ProductOrderItemPrice)`
  font-size: ${rem(14)};
  line-height: ${lh(15.4, 14)};

  del {
    display: block;
  }
`;

const OrderSummaryContainer = styled.div`
  ${media.lg`
    display: none;
  `}
`;

const OrderSummary = styled(OrderCartSummary)`
  [class^='order-cart-summary__Item'] {
    padding: 10px 0 16px 0;
  }

  [class^='order-cart-summary__Label'],
  .unit-price--default {
    font-size: ${rem(14)};
    line-height: ${lh(18.2, 14)};
    color: var(--font-primary-color);
  }

  .total-amount,
  .total-amount-label {
    font-weight: bold;
  }

  ${media.lg`
    min-width: 30%;
  `}
`;

type Props = {
  className?: string;
  order: ListUserOrderData;
};

const OrderBody: React.FC<Props> = ({ className, order }) => {
  const { accessToken } = useAuthState();
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [userProducts, setUserProducts] = useState<UserProduct[]>([]);
  const {
    shipping,
    billing,
    products,
    discount,
    shippingFee,
    promos,
    referral,
    tax,
    refNum,
  } = order;

  useEffect(() => {
    if (!accessToken || !refNum) return;

    (async function () {
      setIsProductLoading(true);
      const data = await listUserProducts(accessToken, { refNum });
      setUserProducts(data);
      setIsProductLoading(false);
    })();
  }, [accessToken, refNum]);

  const getProductWarranty = (orderProduct: ListUserOrderProductItem) => {
    const returnProduct = { ...orderProduct };
    const userProduct = userProducts.find(p => orderProduct.uuid === p.uuid);
    if (!userProduct) {
      // setting to expired will just render as not applicable on UI
      returnProduct.warranty = {
        status: 'expired',
        duration: '',
        expiredOn: '',
      };
    } else {
      returnProduct.warranty = { ...userProduct.warranty };
    }
    return returnProduct;
  };

  const renderOrderSummary = () => (
    <OrderSummary
      products={products}
      amounts={getAmounts(products, discount, shippingFee)}
      shippingFee={shippingFee}
      discount={discount}
      promos={promos}
      referralCode={referral?.code}
      tax={tax}
      disablePromo
    />
  );

  return (
    <Container className={className}>
      <AddressWrapper>
        <AddressContainer>
          <AddressEyebrow>Bill to</AddressEyebrow>
          <AddressInfo>
            {billing.firstName} {billing.lastName}
            <br />
            {billing.address} <br />
            {billing.country} {billing.postalCode}
          </AddressInfo>
        </AddressContainer>
        <AddressContainer>
          <AddressEyebrow>Ship to</AddressEyebrow>
          <AddressInfo>
            {shipping.firstName} {shipping.lastName}
            <br />
            {shipping.address} <br />
            {shipping.country} {shipping.postalCode}
          </AddressInfo>
        </AddressContainer>
      </AddressWrapper>
      <ProductTableContainer>
        <ProductTable>
          <thead>
            <tr>
              <th colSpan={2}>Product</th>
              <th>
                <TableHeaderLabelWithTooltip>
                  Warranty{' '}
                  <Tooltip
                    id="warranty"
                    description="The Woosa Mattress is engineered with quality materials to ensure superior durability night after night. In the unlikely case that your Woosa mattress Is met with any defect, we will replace or repair it without any charge to you. Your new Woosa mattress is covered by a 10 Year Limited Warranty, dating from the time you purchased your new mattress from Woosa Sleep Pte Ltd (“Woosa”). "
                  />
                </TableHeaderLabelWithTooltip>
              </th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, idx) => (
              <tr key={idx}>
                <td>
                  <Image src={product.image} alt="" role="presentation" />
                </td>
                <td>
                  <ProductInfo product={product} />
                </td>
                <td>
                  <WarrantyStatus product={getProductWarranty(product)} />
                </td>
                <td>
                  <ProductPrice product={product} type="unit" />
                </td>
                <td>{product.qty}</td>
                <td>
                  <ProductPrice product={product} type="subtotal" />
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={3}></td>
              <td colSpan={3}>{renderOrderSummary()}</td>
            </tr>
          </tfoot>
        </ProductTable>
        <Spinner isLoading={isProductLoading} />
      </ProductTableContainer>
      <OrderSummaryContainer>{renderOrderSummary()}</OrderSummaryContainer>
    </Container>
  );
};

export default OrderBody;
