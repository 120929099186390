import React, { useState } from 'react';
import styled from 'styled-components';

import { ListUserOrderData } from '../../../../types/order';
import { rem, lh } from '../../../../styles/utils';
import OrderCardModal from './order-card-modal';
import { PriceLabel } from '../../../common';
import ProductImageSummaryView from './product-image-summary-view';
import DownOrderInvoiceBtn from './download-order-invoice-btn';
import { OrderStatusBadge } from '../../common';

type Props = {
  className?: string;
  order: ListUserOrderData;
};

const Container = styled.div`
  width: 100%;
  font-family: var(--font-body);
  font-weight: 400;
  color: var(--font-primary-color);
  position: relative;
  margin-bottom: 20px;
`;

const Wrapper = styled.button`
  background-color: white;
  border-radius: 10px;
  padding: 15px 20px;
  width: 100%;
  border: 0;
  text-align: left;
`;

const DateEyebrow = styled.label`
  display: block;
  font-size: ${rem(14)};
  line-height: ${lh(15.4, 14)};
  margin-bottom: 8px;
`;

const OrderNum = styled.strong`
  display: block;
  font-size: ${rem(24)};
  line-height: ${lh(26.4, 24)};
  margin-bottom: 5px;
  color: var(--font-primary-color);
`;

const ProductInfo = styled.p`
  font-size: ${rem(14)};
  line-height: ${lh(15.4, 14)};
  color: var(--cornflower-blue);
  margin: 0;
`;

const ProductPriceInfo = styled(PriceLabel)`
  font-size: ${rem(14)};
  line-height: ${lh(15.4, 14)};
  color: var(--cornflower-blue);
  margin-left: 5px;
`;

const Footer = styled.div``;

const Body = styled.div`
  margin-bottom: 20px;
  display: flex;
`;

const ProductImageContainer = styled.div`
  margin-left: auto;
`;

const DownInvoiceBtn = styled(DownOrderInvoiceBtn)`
  position: absolute;
  bottom: 10px;
  right: 25px;
  border: 0;
  background-color: transparent;
  padding: 0;
`;

const OrderCard: React.FC<Props> = ({ className, order }) => {
  const [openModal, setOpenModal] = useState(false);
  const { refNum, products, totalPrice, currency } = order;

  const handleClickCard = () => {
    setOpenModal(true);
  };

  return (
    <>
      <Container className={className}>
        <Wrapper onClick={handleClickCard}>
          <Body>
            <div>
              <DateEyebrow></DateEyebrow>
              <OrderNum>{refNum}</OrderNum>
              <ProductInfo>
                {products.length} item{products.length > 1 ? 's' : ''} •
                <ProductPriceInfo
                  price={{
                    amount: totalPrice || 0,
                    currency,
                  }}
                />
              </ProductInfo>
            </div>
            <ProductImageContainer>
              <ProductImageSummaryView products={products} />
            </ProductImageContainer>
          </Body>
          <Footer>
            <OrderStatusBadge order={order} />
          </Footer>
        </Wrapper>
        <DownInvoiceBtn order={order} hideLabel />
      </Container>
      <OrderCardModal
        isActive={openModal}
        onClickClose={() => setOpenModal(false)}
        order={order}
      />
    </>
  );
};

export default OrderCard;
