import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { ListUserOrderProductItem } from '../../../../types/order';
import { rem } from '../../../../styles/utils';

const Container = styled.div`
  font-family: var(--font-body);
  font-weight: 400;
  color: var(--cornflower-blue);
  font-size: ${rem(14)};

  ${media.lg`
    font-size: ${rem(16)};
  `}
`;

const ImageContainer = styled.div``;

const Image = styled.img`
  border: 1px solid var(--harp);
  border-radius: 3px;
  width: 50px;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
`;

const TruncateLabel = styled.span`
  font-size: ${rem(14)};

  ${media.lg`
    font-size: ${rem(12)};
  `}

  ${media.xl`
    font-size: ${rem(14)}; 
  `}
`;

const MAX_PRODUCT_IMAGE = 2;

type Props = {
  className?: string;
  products: ListUserOrderProductItem[];
};

const ProductImageSummaryView: React.FC<Props> = ({ className, products }) => {
  return (
    <Container className={className}>
      <ImageContainer>
        {products.slice(0, MAX_PRODUCT_IMAGE).map((p, i) => (
          <Image key={`${p.uuid}-${i}`} src={p.image} />
        ))}
      </ImageContainer>
      {products.length > MAX_PRODUCT_IMAGE && (
        <TruncateLabel>
          + {products.length - MAX_PRODUCT_IMAGE} more
        </TruncateLabel>
      )}
    </Container>
  );
};

export default ProductImageSummaryView;
