import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { capitalize } from 'lodash';

import { rem, lh } from '../../../styles/utils';
import { HelpTooltip, Carousel } from '../../common';
import { UserProduct } from '../../../types/product';
import { toMoment } from '../../../utils';
import SvgChevronLeft from '../../../static/images/warranty-left-chevron.svg';
import SvgChevronRight from '../../../static/images/warranty-right-chevron.svg';
import useResponsiveCenterSlide from '../../../hooks/use-responsive-center-slide';
import { useAuthState } from '../../../hooks/auth-context';
import { listUserProducts } from '../../../utils/api';

type WarrantyStatusParam = {
  warrantyStatus: 'valid' | 'expired';
};

const Section = styled.section`
  font-family: var(--font-body);
  color: var(--font-primary-color);
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Headline = styled.h2`
  display: flex;
  align-items: center;
  font-family: var(--font-header);
  font-weight: 400;
  font-size: ${rem(24)};
  line-height: ${lh(34, 24)};

  ${media.md`
    font-size: ${rem(30)};
    line-height: ${lh(34, 30)}; 
  `}
`;

const Tooltip = styled(HelpTooltip)`
  margin-left: 5px;
  opacity: 0.5;

  svg {
    fill: var(--cornflower-blue);
  }
`;

const ProductCarousel = styled(Carousel)``;

const ProductContainer = styled.div`
  padding-right: 10px;
`;

const ProductItem = styled.div<WarrantyStatusParam>`
  background-color: white;
  position: relative;
  padding-top: 30px;
  padding-bottom: 15px;
  border-radius: 10px;
  margin-right: 10px;
  border-bottom: ${({ warrantyStatus }) =>
    warrantyStatus === 'valid'
      ? '10px solid var(--pastel-green)'
      : '10px solid var(--mandy)'};

  &:last-child {
    margin-right: 0;
  }
`;

const ProductImage = styled.img`
  width: 100%;
`;

const ProductInfo = styled.div`
  padding: 10px 10px 0 10px;
  text-align: left;
`;

const WarrantyStatus = styled.strong<WarrantyStatusParam>`
  display: block;
  margin-bottom: 5px;
  font-weight: 400;
  color: ${({ warrantyStatus }) =>
    warrantyStatus === 'valid' ? 'var(--pastel-green)' : 'var(--mandy)'};
`;

const ProductText = styled.p`
  margin: 0;
`;

const WarrantyStatusIndicator = styled.div<WarrantyStatusParam>`
  border-radius: 100%;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: ${({ warrantyStatus }) =>
    warrantyStatus === 'valid' ? 'var(--pastel-green)' : 'var(--mandy)'};
`;

const ActionContainer = styled.div`
  margin-left: auto;
`;

const ChevronRight = styled(SvgChevronRight)`
  cursor: pointer;
`;

const ChevronLeft = styled(SvgChevronLeft)`
  margin-left: 10px;
  cursor: pointer;
`;

type Props = {
  className?: string;
};

const ProductWarranty: React.FC<Props> = () => {
  const { accessToken } = useAuthState();
  const [products, setProducts] = useState<UserProduct[]>([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const centerSlidePercentage = useResponsiveCenterSlide({
    xs: 70,
    sm: 40,
    md: 60,
    lg: 45,
    xl: 40,
  });

  useEffect(() => {
    if (!accessToken) return;

    (async function () {
      const userProducts = await listUserProducts(accessToken, { size: 200 });
      setProducts(userProducts);
    })();
  }, [accessToken]);

  const updateCurrentSlide = (index: number) => {
    if (currentSlide === index) return;
    setCurrentSlide(index);
  };

  return (
    <Section>
      <Header>
        <Headline>
          Warranty{' '}
          <Tooltip
            id="warranty"
            description="The Woosa Mattress is engineered with quality materials to ensure superior durability night after night. In the unlikely case that your Woosa mattress Is met with any defect, we will replace or repair it without any charge to you. Your new Woosa mattress is covered by a 10 Year Limited Warranty, dating from the time you purchased your new mattress from Woosa Sleep Pte Ltd (“Woosa”). "
          />
        </Headline>
        <ActionContainer>
          <ChevronRight onClick={() => updateCurrentSlide(currentSlide - 1)} />
          <ChevronLeft onClick={() => updateCurrentSlide(currentSlide + 1)} />
        </ActionContainer>
      </Header>
      <ProductCarousel
        props={{
          showThumbs: false,
          showStatus: false,
          centerMode: true,
          showIndicators: false,
          centerSlidePercentage,
          selectedItem: currentSlide,
          onChange: updateCurrentSlide,
        }}
      >
        {products.map(
          ({ uuid, warranty: { status, expiredOn }, image, name }) => (
            <ProductContainer key={uuid}>
              <ProductItem warrantyStatus={status}>
                <WarrantyStatusIndicator warrantyStatus={status} />
                <ProductImage src={image} />
                <ProductInfo>
                  <WarrantyStatus warrantyStatus={status}>
                    {capitalize(status)}
                  </WarrantyStatus>
                  <ProductText>
                    {name} <br />
                    Expire on {toMoment(expiredOn).format('DD MMM YYYY')}
                  </ProductText>
                </ProductInfo>
              </ProductItem>
            </ProductContainer>
          )
        )}
      </ProductCarousel>
    </Section>
  );
};

export default ProductWarranty;
