import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { Tabs, Tab, TabPanel, TabList } from '../../common';
import { rem, lh } from '../../../styles/utils';
import OrderTransaction from './order-transaction';

const Section = styled.section`
  font-family: var(--font-body);
  color: var(--font-primary-color);
  margin-top: 40px;
`;

const Headline = styled.h2`
  display: flex;
  align-items: center;
  font-family: var(--font-header);
  font-weight: 400;
  font-size: ${rem(24)};
  line-height: ${lh(34, 24)};
  margin-top: 0;

  ${media.md`
    font-size: ${rem(30)};
    line-height: ${lh(34, 30)}; 
  `}
`;

const TabNavbar = styled(TabList)`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  widht: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
`;

const TabNavItem = styled(Tab)`
  font-size: ${rem(16)};
  line-height: ${lh(21, 16)};
  font-weight: 600;
  margin: 0 20px;
  opacity: 0.3;
  padding-bottom: 5px;

  &.react-tabs__tab--selected {
    background-color: transparent;
    opacity: 1;
    border-bottom: 2px solid var(--firefly);
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

type Props = {
  className?: string;
};

const Transaction: React.FC<Props> = () => {
  const tabs = [{ title: 'Orders', Component: OrderTransaction }];

  return (
    <Section>
      <Headline>Transactions</Headline>
      <Tabs>
        <TabNavbar>
          {tabs.map(({ title }) => (
            <TabNavItem key={`tab-nav-item-${title}`}>{title}</TabNavItem>
          ))}
        </TabNavbar>
        {tabs.map(({ title, Component }) => (
          <TabPanel key={`tab-panel-${title}`}>
            <Component />
          </TabPanel>
        ))}
      </Tabs>
    </Section>
  );
};

export default Transaction;
