import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import OrderCard from './order-card';
import OrderAccordion from './order-accordion';
import { ListUserOrderData } from '../../../../types/order';
import { flatButtonStyle } from '../../../../styles/utils';
import { useAuthState } from '../../../../hooks/auth-context';
import { listUserOrders } from '../../../../utils/api';
import { Spinner } from '../../../common';

const Container = styled.div`
  width: 100%;
`;

const OrderAccordionContainer = styled.div`
  display: none;

  ${media.lg`
    display: block;
  `}
`;

const OrderCardContainer = styled.div`
  ${media.lg`
    display: none;
  `}
`;

const OrderActionContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ViewMoreButton = styled.button`
  ${flatButtonStyle()}
  ${media.lg`
    min-width: 200px;
  `}
`;

type Props = {
  className?: string;
};

const OrderTransaction: React.FC<Props> = () => {
  const { accessToken } = useAuthState();
  const [orders, setOrders] = useState<ListUserOrderData[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!accessToken) return;
    (async function () {
      await getOrders();
    })();
  }, [accessToken]);

  const getOrders = async () => {
    setIsLoading(true);
    const cursor = orders.length > 0 ? orders[orders.length - 1].id : '';
    const orderData = await listUserOrders(accessToken, {
      cursor,
      size: 10,
      orderBy: 'refNum',
      order: 'desc',
    });
    setOrders([...orders, ...orderData]);
    setIsLoading(false);
  };

  const handleClickViewMoreButton = async () => {
    await getOrders();
  };

  return (
    <Container>
      <OrderAccordionContainer>
        <OrderAccordion orders={orders} />
      </OrderAccordionContainer>
      <OrderCardContainer>
        {orders.map(order => (
          <OrderCard key={order.refNum} order={order} />
        ))}
      </OrderCardContainer>
      <OrderActionContainer>
        <ViewMoreButton onClick={handleClickViewMoreButton}>
          View more
        </ViewMoreButton>
      </OrderActionContainer>
      <Spinner isLoading={isLoading} />
    </Container>
  );
};

export default OrderTransaction;
