import React from 'react';
import { Row, Col } from 'styled-bootstrap-grid';

import {
  ReferralCashback,
  ProductWarranty,
  Transaction,
} from '../../components/account/dashboard';
import { AccountHero, AccountLayout } from '../../components/account/common';
import { SiteUrls } from '../../utils/constants';
import SvgAccountSetting from '../../static/images/account-settings-icon.svg';

const DashboardPage = (): JSX.Element => (
  <AccountLayout
    hero={
      <AccountHero
        toolbarLink={{
          icon: <SvgAccountSetting />,
          to: SiteUrls.AccountGeneral,
          text: 'Account Settings',
        }}
      />
    }
  >
    <Row>
      <Col xs={12} lg={6}>
        <ReferralCashback />
      </Col>
      <Col xs={12} lg={6}>
        <ProductWarranty />
      </Col>
    </Row>
    <Transaction />
  </AccountLayout>
);

export default DashboardPage;
