import React, { useState } from 'react';
import styled from 'styled-components';
import { useAuthState } from '../../../../hooks/auth-context';

import SvgDownload from '../../../../static/images/dashboard-order-download.svg';
import { rem, lh } from '../../../../styles/utils';
import { ListUserOrderData } from '../../../../types/order';
import { downloadOrderInvoice } from '../../../../utils/api';

const DownloadIcon = styled(SvgDownload)`
  margin-left: 10px;
`;

const Button = styled.button`
  border: 0;
  padding: 0;
  background-color: transparent;
  color: var(--leather);
  font-size: ${rem(14)};
  line-height: ${lh(14, 14)};
  display: flex;
  align-items: center;
  cursor: pointer;

  :hover:enabled {
    ${DownloadIcon} {
      opacity: 0.6;
    }
  }

  :disabled {
    cursor: not-allowed;
  }
`;

const Label = styled.span``;

type Props = {
  className?: string;
  order: ListUserOrderData;
  hideLabel?: boolean;
};

const DownOrderInvoiceBtn: React.FC<Props> = ({
  className,
  order,
  hideLabel,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { accessToken } = useAuthState();

  const handleClick = async () => {
    if (isLoading) return;
    setIsLoading(true);
    await downloadOrderInvoice(accessToken, order);
    setIsLoading(false);
  };

  return (
    <Button className={className} onClick={handleClick} disabled={isLoading}>
      {!hideLabel && <Label>Download invoice</Label>}
      <DownloadIcon />
    </Button>
  );
};

export default DownOrderInvoiceBtn;
