import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { WEB_HOST, ENABLE_REFERRAL } from 'gatsby-env-variables';

import { HelpTooltip, CopyInput } from '../../common';
import { ImgFacebookIcon, ImgInstagramIcon } from '../../assets';
import { rem, lh } from '../../../styles/utils';
import SvgWallet from '../../../static/images/wallet-icon.svg';
import SvgWithdraw from '../../../static/images/withdraw-icon.svg';

const Section = styled.section`
  font-family: var(--font-body);
  color: var(--font-primary-color);
`;

const Headline = styled.h2`
  display: flex;
  align-items: center;
  font-family: var(--font-header);
  font-weight: 400;
  font-size: ${rem(24)};
  line-height: ${lh(34, 24)};

  ${media.md`
    font-size: ${rem(30)};
    line-height: ${lh(34, 30)}; 
  `}
`;

const Tooltip = styled(HelpTooltip)<{ marginLeft?: number }>`
  margin-left: ${({ marginLeft }) => marginLeft || 5}px;
  opacity: 0.5;

  svg {
    fill: var(--cornflower-blue);
  }
`;

const Card = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  position: relative;
`;

const Eyebrow = styled.label<{ textAlign?: 'center' | 'left' }>`
  font-weight: 700;
  font-size: ${rem(14)};
  line-height: ${lh(15.4, 14)};
  margin-bottom: 10px;
  display: block;
  text-align: ${({ textAlign }) => textAlign || 'center'};

  ${media.md`
      text-align: left;
  `}
`;

const WalletIcon = styled(SvgWallet)`
  margin-right: 15px;
`;

const MainBalance = styled.div`
  font-weight: 700;
  font-size: ${rem(60)};
  line-height: ${lh(66, 60)};
  display: flex;
  justify-content: center;

  ${media.md`
    justify-content: initial;
  `}
`;

const SubBalance = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.md`
      align-items: initial;
      justify-content: initial;
      flex-direction: row;
  `}
`;

const SubBalanceItem = styled.li`
  font-weight: 400;
  font-size: ${rem(14)};
  line-height: ${lh(15.4, 14)};
  display: flex;
  align-items: center;

  ${media.md`
      &:last-child {
        padding-left: 20px;
      }
  `}
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  ${media.md`
      flex-direction: row;
      align-items: center;
  `}
`;

const ReferralLink = styled.div`
  ${media.md`
      flex-grow: 2;
  `}
`;

const ReferralInput = styled(CopyInput)``;

const SocialIcons = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0 0 0;
  display: flex;
  justify-content: center;

  ${media.md`
      margin-left: 30px;
      margin-top: 0;
  `}
`;

const SocialItem = styled.li`
  margin-left: 5px;

  &:first-child {
    margin-left: 0;
  }
`;

const SocialLink = styled.a`
  width: 30px;
  display: block;
`;

const WithdrawFromBankButton = styled.button`
  border: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  position: absolute;
  top: 16px;
  right: 16px;

  ${media.md`
    top: 25px;
    right: 25px;
  `}
`;

const WithdrawFromBankLabel = styled.label`
  color: var(--leather);
  font-size: ${rem(14)};
  line-height: ${lh(15.4, 14)};
  margin-left: 8px;
  display: none;

  ${media.md`
    display: block;
  `}
`;

const BlurOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  position: absolute;
`;

const DisableOverlay = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
`;

const TextOverlay = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-header);
  font-size: ${rem(21)};
`;

type Props = {
  className?: string;
};

const ReferralCashback: React.FC<Props> = () => {
  return (
    <Section>
      <Headline>
        Referral Cashback{' '}
        <Tooltip
          id="referral-cashback"
          headline="Get 10% cashback when you refer your friend*"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sem felis, sagittis et urna ac, congue pretium nisl. In sit amet consectetur nunc. Aliquam mattis ex in fermentum pulvinar."
        />
      </Headline>
      <Card>
        <Eyebrow>Your accumulated cashback</Eyebrow>
        <MainBalance>
          <WalletIcon />
          S$0
        </MainBalance>
        <SubBalance>
          <SubBalanceItem>
            Available Balance: S$0{' '}
            <Tooltip
              id="available-balance"
              description="Amount you’ve earned that can be withdrawn to your bank account or use to offset your next purchase."
              marginLeft={2}
            />
          </SubBalanceItem>
          <SubBalanceItem>
            Redeemed amount: S$0{' '}
            <Tooltip
              id="redeemed-amount"
              description="Amount you’ve cashed out or use to offset your purchase."
              marginLeft={2}
            />
          </SubBalanceItem>
        </SubBalance>
        <Footer>
          <ReferralLink>
            <Eyebrow textAlign="left">Your referral link</Eyebrow>
            <ReferralInput value={WEB_HOST} />
          </ReferralLink>
          <SocialIcons>
            <SocialItem>
              <SocialLink href="">
                <ImgFacebookIcon />
              </SocialLink>
            </SocialItem>
            <SocialItem>
              <SocialLink href="">
                <ImgInstagramIcon />
              </SocialLink>
            </SocialItem>
          </SocialIcons>
        </Footer>
        <WithdrawFromBankButton>
          <SvgWithdraw />
          <WithdrawFromBankLabel>Withdraw to bank</WithdrawFromBankLabel>
        </WithdrawFromBankButton>
        {!ENABLE_REFERRAL && (
          <DisableOverlay>
            <BlurOverlay />
            <TextOverlay>Coming Soon</TextOverlay>
          </DisableOverlay>
        )}
      </Card>
    </Section>
  );
};

export default ReferralCashback;
