import React from 'react';
import styled from 'styled-components';

import { Modal as CustomModal } from '../../../common';
import { ListUserOrderData } from '../../../../types/order';
import { rem, lh } from '../../../../styles/utils';
import OrderBody from './order-body';
import { toMoment } from '../../../../utils';
import DownOrderInvoiceBtn from './download-order-invoice-btn';

const Modal = styled(CustomModal)`
  font-family: var(--font-body);
  font-weight: 400;
  color: var(--font-primary-color);

  .modal-container {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
    padding: 0;
  }

  [class^='custom-modal__Body-'] {
    height: 100%;
    max-height: none;
    max-width: none;
    position: relative;
  }
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const OrderNum = styled.h3`
  font-family: var(--font-header);
  font-size: ${rem(24)};
  line-height: ${lh(34, 24)};
  margin-bottom: 2px;
`;

const DateEyebrow = styled.label`
  font-size: ${rem(14)};
  line-height: ${lh(14, 14)};
`;

const Body = styled.div``;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 30px;
  padding-top: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const DownloadButton = styled(DownOrderInvoiceBtn)`
  margin-left: auto;
`;

const CloseButton = styled.button`
  border: 0;
  padding: 0;
  background-color: transparent;
  color: var(--font-primary-color);
`;

type Props = {
  className?: string;
  isActive: boolean;
  onClickClose?: () => void;
  order: ListUserOrderData;
};

const OrderCardModal: React.FC<Props> = ({
  className,
  isActive,
  onClickClose,
  order,
}) => {
  return (
    <Modal
      className={`dashboard-order-modal ${className}`}
      isActive={isActive}
      onClickClose={onClickClose}
      backdrop="allowClose"
      hideCloseBtn
    >
      <Header>
        <div>
          <OrderNum>{order.refNum}</OrderNum>
          <DateEyebrow>
            {toMoment(order.orderedOn).format('DD MMMM YYYY')}
          </DateEyebrow>
        </div>
        <DownloadButton order={order} />
      </Header>
      <Body>
        <OrderBody order={order} />
      </Body>
      <Footer>
        <CloseButton onClick={onClickClose}>Close</CloseButton>
      </Footer>
    </Modal>
  );
};

export default OrderCardModal;
