import React from 'react';
import styled from 'styled-components';

import { ListUserOrderProductItem } from '../../../../types/order';
import { toMoment } from '../../../../utils';
import { rem, lh } from '../../../../styles/utils';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const StatusIndicator = styled.div<{
  status: 'expired' | 'valid';
}>`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: ${({ status }) =>
    status === 'valid' ? 'var(--pastel-green)' : 'var(--cornflower-blue)'};
  margin-right: 8px;
`;

const TextContainer = styled.div`
  font-size: ${rem(14)};
  font-weight: 400;
  line-height: ${lh(14, 15.4)};
`;

const Label = styled.label``;

const ValidLabel = styled.strong`
  color: var(--pastel-green);
  font-weight: 400;
  display: block;
  margin-bottom: 4px;
`;

type Props = {
  className?: string;
  product: ListUserOrderProductItem;
};

const ProductWarrantyStatus: React.FC<Props> = ({ className, product }) => {
  const { status, expiredOn, duration } = product.warranty || {};

  return (
    <Container className={className}>
      <StatusIndicator status={status} />
      <TextContainer>
        {status === 'valid' && (
          <Label>
            <ValidLabel>Valid ({duration})</ValidLabel>
            Expire on {toMoment(expiredOn).format('DD MMMM YYYY')}
          </Label>
        )}
        {status !== 'valid' && <Label>Not applicable</Label>}
      </TextContainer>
    </Container>
  );
};

export default ProductWarrantyStatus;
